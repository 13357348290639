import { Bookmark } from './bookmarkMapper.interface'
import { DecoratedEstate } from './decoratedItemEstate.interface'

export function bookmarkOf(item: DecoratedEstate): Bookmark {
    return {
        address: buildAddress(item.geoDataVisible, item.postcode, item.city, item.street),
        detailUrl: item.detailUrl,
        title: item.title,
        smallImageUrl: item.smallImageUrl,
        street: item.street,
        postcode: item.postcode,
        city: item.city,
        price: item.price,
        squareMeterPrice: item.squareMeterPrice,
        priceAppendix: item.priceAppendix,
        livingArea: item.livingArea,
        livingAreaText: item.livingAreaText,
        landArea: item.landArea,
        rooms: item.rooms,
        createdDateFormatted: item.createdDateFormatted,
        newestEstate: item.newestEstate,
        guid: item.exposeeId,
        status: item.status,
        commercialExpose: item.commercialExpose,
    }
}

function buildAddress(geoDataVisible: boolean, postcode: string, city: string, street?: string): string {
    if (!geoDataVisible) {
        return city
    }

    let address = `${postcode} ${city}`

    if (street) {
        address += `, ${street}`
    }

    return address
}
